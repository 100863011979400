import styled from '@emotion/styled';
import { ActivityComponentType } from '@stackflow/react';

import MainContents from './components/MainContents';

import HomeAppScreen from './components/HomeAppScreen';

const HomePage: ActivityComponentType = () => {
  return (
    <HomeAppScreen>
      <Wrapper>
        <MainContents />
      </Wrapper>
    </HomeAppScreen>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  overflow: hidden;
`;

export default HomePage;
