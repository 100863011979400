type ActiveStateProps = {
  viewCount: number[];
  likeCount: number[];
  dislikeCount: number[];
  publishedAt: string;
  subscriberCount: number[];
};

export function calculateActiveRate(props: ActiveStateProps): number[] {
  const now = new Date();
  const publishedData = new Date(props.publishedAt);

  const diffInDays = Math.floor((now.getTime() - publishedData.getTime()) / (1000 * 60 * 60 * 24));
  const dayDiffWeight = Math.max(1, 31 - diffInDays);

  const scores = [];
  for (let i = 1; i < props.viewCount.length; i++) {
    const viewCountDiff = props.viewCount[i] - props.viewCount[i - 1];
    const likeCountDiff = props.likeCount[i] - props.likeCount[i - 1];
    const dislikeCountDiff = props.dislikeCount[i] - props.dislikeCount[i - 1];
    const subscriberCountDiff = props.subscriberCount[i] - props.subscriberCount[i - 1];
    const subscriberWeight = 1 + Math.log10(subscriberCountDiff + 1);
    const viewCountWeight = 1 + Math.log10(viewCountDiff + 1);
    const likeCountWeight = 1 + Math.log10(likeCountDiff + 1);
    const dislikeCountWeight = 1 + Math.log10(dislikeCountDiff + 1);

    const activeRate =
      (viewCountWeight * 0.5 + likeCountWeight * 0.25 + dislikeCountWeight * 0.25) *
      subscriberWeight *
      dayDiffWeight;

    scores.push(activeRate);
  }
  return scores;
}
