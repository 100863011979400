import { VideoType } from './searchedDataUtil';

export const calcSortScore = (video: VideoType) => {
  let points = 0;

  const lastVideoViewCount = video.view_count[video.view_count.length - 1];

  if (lastVideoViewCount > 100000) points += 4;
  else if (lastVideoViewCount > 20000) points += 3;
  else if (lastVideoViewCount > 10000) points += 2;
  else points += 0;

  const lastVideoSubscriberCount = video.channel_subscriber[video.channel_subscriber.length - 1];

  if (lastVideoSubscriberCount < 10000) points += 4;
  else if (lastVideoSubscriberCount < 100000) points += 3;
  else points += 1;

  if (isPublishedInWeek(video.published_at)) points += 1;

  return points;
};

// 일주일 내 게시 여부
export const isPublishedInWeek = (strDate: string) => {
  const publishedAt = new Date(strDate);
  const now = Date.now();
  const weekAgo = new Date(now - 1000 * 60 * 60 * 24 * 7); // 6개월 전 날짜

  return publishedAt >= weekAgo;
};

// 같은 point인 경우 정렬 로직 -
export const calcSortSameScore = (video: VideoType) => {
  const videoRatio =
    video.view_count[video.view_count.length - 1] /
    video.channel_subscriber[video.channel_subscriber.length - 1];

  return Math.floor(videoRatio);
};

export const sortVideos = (videos: VideoType[]) => {
  return videos.sort((a, b) => {
    // published_at이 같다면 view_count와 subscriber_count로 점수를 계산하여 정렬
    const aScore = calcSortScore(a);
    const bScore = calcSortScore(b);
    if (aScore < bScore) return 1;
    if (aScore > bScore) return -1;

    if (aScore === bScore) {
      const aSameScore = calcSortSameScore(a);
      const bSameScore = calcSortSameScore(b);
      if (aSameScore === bSameScore) return a.published_at < b.published_at ? 1 : -1;
      return aSameScore < bSameScore ? 1 : -1;
    }

    return 0;
  });
};

// 전체 검색된 비디오 정렬 - 홈 노출확률 기준 정렬
export const sortAllSearchedVideos = (videos: VideoType[]) => {
  return videos.sort((a, b) => {
    // 조회수 Diff로 선 정렬
    const aViewFirstIndex = a.view_count.length > 1 ? a.view_count.length - 2 : 0;
    const aViewDiff = a.view_count[a.view_count.length - 1] - a.view_count[aViewFirstIndex];
    const aViewDiffFloor = Math.floor(aViewDiff / 1000);

    const bViewFirstIndex = b.view_count.length > 1 ? b.view_count.length - 2 : 0;
    const bViewDiff = b.view_count[b.view_count.length - 1] - b.view_count[bViewFirstIndex];
    const bViewDiffFloor = Math.floor(bViewDiff / 1000);

    if (aViewDiffFloor === bViewDiffFloor) {
      const aSubscriberFirstIndex =
        a.channel_subscriber.length > 1 ? a.channel_subscriber.length - 2 : 0;
      const aSubscriberDiff =
        a.channel_subscriber[a.channel_subscriber.length - 1] -
        a.channel_subscriber[aSubscriberFirstIndex];
      const aSubscriberDiffFloor = Math.floor(aSubscriberDiff / 1000);

      const bSubscriberFirstIndex =
        b.channel_subscriber.length > 1 ? b.channel_subscriber.length - 2 : 0;
      const bSubscriberDiff =
        b.channel_subscriber[b.channel_subscriber.length - 1] -
        b.channel_subscriber[bSubscriberFirstIndex];
      const bSubscriberDiffFloor = Math.floor(bSubscriberDiff / 1000);

      if (aSubscriberDiffFloor === bSubscriberDiffFloor) {
        return b.activeRate[b.activeRate.length - 1] - a.activeRate[a.activeRate.length - 1];
      }
      return bSubscriberDiffFloor - aSubscriberDiffFloor;
    }
    return bViewDiffFloor - aViewDiffFloor;
  });
};
