import { useMutation, useQuery } from 'react-query';
import { VideoType } from '../pages/HomePage/utils/searchedDataUtil';
import { API_BASE_URL, fetchInstance } from './base';

export type SearchKewordProps = {
  limit?: number;
  keyword: string;
};

export type SearchKewordResponse = {
  success: boolean;
  keyword: string;
  data: VideoType[];
};

const getVideoByKeywordPath = `${API_BASE_URL}/youtube/search`;

export const fetchGetVideoByKeyword = async (req: SearchKewordProps) => {
  const result = await fetchInstance().get(getVideoByKeywordPath, {
    params: {
      keyword: req.keyword,
      limit: req.limit,
    },
  });
  return result.data;
};

// export const useGetVideoByKeyword = () => {
//   return useMutation<SearchKewordResponse, Error, SearchKewordProps>(fetchGetVideoByKeyword);
// };

export const useGetVideoByKeyword = ({ keyword, limit = 50 }: SearchKewordProps) => {
  return useQuery<SearchKewordProps, Error, SearchKewordResponse>(
    [getVideoByKeywordPath],
    () => fetchGetVideoByKeyword({ keyword, limit }),
    {
      enabled: false,
    }
  );
};
