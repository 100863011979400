import { storage } from '../../../hooks/useStorage';
import { calculateActiveRate } from './calcActiveRate';

// 데이터 모델 정의
export type VideoType = {
  id: string;

  title: string;
  description: string;
  video_tags: string[];
  view_count: number[];
  like_count: number[];
  dislike_count: number[];
  comment_count: number[];
  published_at: string;
  published_at_text?: string;
  searchDate: string[];

  channel_id: string;
  channel_title: string;
  channel_description: string;
  channel_subscriber: number[];
  channel_view_count: number[];

  activeRate: number[];

  url: string;
  duration: string;
  channel_avatar: string;
  thumbnail: string;
};

export const setSearchedData = ({
  keyword,
  value,
}: {
  keyword: string;
  value: VideoType[];
}): void => {
  const currentStorage = storage('searchedData');
  const currentData = currentStorage.get();
  if (!currentData) {
    currentStorage.set({ [keyword]: value });
    return;
  }
  if (!currentData[keyword]) {
    currentStorage.set({ ...currentData, [keyword]: value });
    return;
  }

  const storedData = currentData[keyword];

  // 비디오 데이터 저장
  for (const item of value) {
    // 존재하면 기존 데이터에 추가
    const index = storedData.findIndex((v: VideoType) => v.id === item.id);
    const hasDiffOfViewCount =
      item.view_count[0] -
        storedData[index]?.view_count[storedData[index]?.view_count.length - 1] !==
      0;
    const hasDiffOfChannelSubscriber =
      item.channel_subscriber[0] -
        storedData[index]?.channel_subscriber[storedData[index]?.channel_subscriber.length - 1] !==
      0;
    if (index !== -1) {
      if (hasDiffOfViewCount || hasDiffOfChannelSubscriber) {
        // 둘 중 하나라도 차이가 있으면 저장
        const activeRate = calculateActiveRate({
          viewCount: [...storedData[index].view_count, ...item.view_count],
          likeCount: [...storedData[index].like_count, ...item.like_count],
          dislikeCount: [...storedData[index].dislike_count, ...item.dislike_count],
          publishedAt: item.published_at,
          subscriberCount: [...storedData[index].channel_subscriber, ...item.channel_subscriber],
        });
        storedData[index] = {
          ...storedData[index],
          published_at_text: item.published_at_text,
          view_count: [...storedData[index].view_count, ...item.view_count],
          like_count: [...storedData[index].like_count, ...item.like_count],
          dislike_count: [...storedData[index].dislike_count, ...item.dislike_count],
          comment_count: [...storedData[index].comment_count, ...item.comment_count],
          searchDate: [...storedData[index].searchDate, ...item.searchDate],
          channel_subscriber: [...storedData[index].channel_subscriber, ...item.channel_subscriber],
          channel_view_count: [...storedData[index].channel_view_count, ...item.channel_view_count],
          activeRate: activeRate,
        };
      }

      // 둘 중 하나라도 차이가 없으면 아무것도 하지 않음
    } else {
      // 존재하지 않으면 새로 추가
      storedData.push(item);
    }
  }

  currentData[keyword] = storedData;

  currentStorage.set(currentData);
};

export const getSearchedData = ({ keyword }: { keyword: string }) => {
  const storageData = storage('searchedData');
  const currentStorage = storageData.get();

  const currentKeywordData = currentStorage?.[keyword] ?? [];
  return currentKeywordData as VideoType[];
};

export const setDeleteSearchedData = ({ keyword }: { keyword: string }): void => {
  const currentStorage = storage('searchedData');
  const currentData = currentStorage.get();
  if (!currentData) {
    currentStorage.set({});
    return;
  }
  if (!currentData[keyword]) {
    return;
  }

  const filteredData = filterDataByKey(currentData, keyword);

  currentStorage.set(filteredData);
};

function filterDataByKey(currentData: any, key: string): any[] {
  // 객체에서 해당 키 이름을 제거합니다.
  const { [key]: omittedKey, ...rest } = currentData;

  // 나머지 속성들의 값들만 추출하여 배열로 반환합니다.
  return rest;
}
