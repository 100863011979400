import styled from '@emotion/styled';
import { AppScreen } from '@stackflow/plugin-basic-ui';
import React, { useCallback } from 'react';
import { useFlow } from '../../../stackflow';
import Flask from '../../../assets/Image/flask.png';
import useFormContextHook from '../../../hooks/useFormContextHook';
import { keyframes } from '@emotion/react';

type Props = React.HTMLAttributes<HTMLDivElement>;

const HomeAppScreen = ({ children, ...props }: Props) => {
  const { push } = useFlow();
  const { watch } = useFormContextHook();
  const result = watch('result');
  const scrolledToContents = watch('scrolledToContents') ?? false;

  const renderTitle = useCallback(() => {
    if (!scrolledToContents) return null;
    if (!result) return <div></div>;
    return (
      <TextInfo>
        <p style={{ marginRight: '5px', fontWeight: '700', fontSize: '18px' }}>{result?.keyword}</p>
        검색결과 :{' '}
        <p style={{ margin: '0 5px', fontWeight: '700', fontSize: '18px' }}>
          {result?.value?.length ?? 0}
        </p>
        개
      </TextInfo>
    );
  }, [result, scrolledToContents]);

  return (
    <AppScreen
      {...props}
      appBar={{
        title: renderTitle(),
        borderColor: '#cccccc64',
        border: true,
        borderSize: '1px',
        backButton: {
          renderIcon: () => {
            return <MyIconWrapper src={Flask} />;
          },
          onClick: () => {
            push('HomePage', {}, { animate: false });
          },
        },
        closeButton: {
          renderIcon: () => {
            return <MyIconWrapper src={Flask} />;
          },
          onClick: () => {
            push('HomePage', {}, { animate: false });
          },
        },
        appendRight: () => {
          return <></>;
        },
        appendLeft: () => {
          return (
            <TopMenuWrapper>
              <Title>Dave AI</Title>
              <Item>Home</Item>
              <Item>My Page</Item>
            </TopMenuWrapper>
          );
        },
      }}
    >
      {children}
    </AppScreen>
  );
};

export default HomeAppScreen;

const TopMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 800;
  color: #2a2a2a;
  margin-right: 30px;

  cursor: pointer;
`;

const Item = styled.div`
  font-size: 14px;
  color: #626262;
  margin-right: 10px;
  cursor: pointer;
`;

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    }
`;

const MyIconWrapper = styled.img`
  margin-right: 8px;
  width: 23px;
  height: 23px;
  animation: ${Spin} 2s linear infinite;
`;

const TextInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
