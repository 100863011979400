import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import { isPublishedIn6Months } from '../../../../utils/dayUtil';
import { ContentsResultType } from '../ContentsSection/ContentsSection';
import { isPublishedInWeek, sortAllSearchedVideos, sortVideos } from '../../utils/sortUtil';
import VideoItem from './VideoItem';
import useFormContextHook from '../../../../hooks/useFormContextHook';
import { SortContents } from '../MainContents';

const VideoList = ({ value }: ContentsResultType) => {
  const { watch } = useFormContextHook();
  const sort: SortContents = watch('sort');
  const isSortAsDiff = sort === 'diffCount';

  const inWeekVideos = useMemo(() => {
    if (isSortAsDiff) {
      return sortAllSearchedVideos(
        value?.filter((video) => {
          return isPublishedInWeek(video.published_at);
        }) ?? []
      );
    }
    return sortVideos(
      value?.filter((video) => {
        return isPublishedInWeek(video.published_at);
      }) ?? []
    );
  }, [isSortAsDiff, value]);

  const in6MonthsVideo = useMemo(() => {
    if (isSortAsDiff) {
      return sortAllSearchedVideos(
        value?.filter((video) => {
          return isPublishedIn6Months(video.published_at) && !isPublishedInWeek(video.published_at);
        }) ?? []
      );
    }
    return sortVideos(
      value?.filter((video) => {
        return isPublishedIn6Months(video.published_at) && !isPublishedInWeek(video.published_at);
      }) ?? []
    );
  }, [isSortAsDiff, value]);

  const notIn6MonthsVideo = useMemo(() => {
    if (isSortAsDiff) {
      return sortAllSearchedVideos(
        value?.filter((video) => {
          return (
            !isPublishedIn6Months(video.published_at) && !isPublishedInWeek(video.published_at)
          );
        }) ?? []
      );
    }
    return sortVideos(
      value?.filter((video) => {
        return !isPublishedIn6Months(video.published_at) && !isPublishedInWeek(video.published_at);
      }) ?? []
    );
  }, [isSortAsDiff, value]);

  return (
    <ListWrapper>
      {inWeekVideos.length !== 0 && (
        <>
          <Title>
            <span>일주일</span> 내 업로드 영상
          </Title>
          {inWeekVideos?.map((item) => {
            return <VideoItem key={item.id} item={item} />;
          })}
        </>
      )}
      {in6MonthsVideo.length !== 0 && (
        <>
          <Title>
            <span>6개월</span> 내 업로드 영상
          </Title>
          {in6MonthsVideo?.map((item) => {
            return <VideoItem key={item.id} item={item} />;
          })}
        </>
      )}
      <Divide>
        <Title>
          <span>6개월</span> 이전 업로드 영상
        </Title>
      </Divide>
      {notIn6MonthsVideo?.map((item) => {
        return <VideoItem key={item.id} item={item} />;
      })}
    </ListWrapper>
  );
};

export default VideoList;

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 10px;
`;

const Divide = styled.div`
  padding: 20px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;

  span {
    color: #ef8605;
  }
`;
