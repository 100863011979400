import styled from '@emotion/styled';
import { Spacing } from '../../../components/common/Spacing';
import Spinner from '../../../components/common/Spinner';

const EmptyResult = () => {
  return (
    <Wrapper>
      <Spinner size={30} color={'#F86E6E'} />
      <Spacing height={30} />
      <h4>검색중입니다. 잠시만 기다려주세요</h4>
    </Wrapper>
  );
};

export default EmptyResult;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
