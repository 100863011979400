import styled from '@emotion/styled';
import React from 'react';
import useFormContextHook from '../../../../hooks/useFormContextHook';
import {
  getSearchedData,
  setDeleteSearchedData,
  setSearchedData,
} from '../../utils/searchedDataUtil';
import CloseIcon from '@mui/icons-material/Close';

const RecentKeywordSection = () => {
  const { setValue, watch } = useFormContextHook();

  const recentKeywordValue = watch('recentKeyword') ?? [];

  const recentKeywordHandler = (keyword: string) => {
    const storedData = getSearchedData({ keyword });
    if (!storedData) return;

    setValue('result', {
      keyword: keyword,
      value: storedData,
    });
    setValue('keyword', keyword);
  };

  return (
    <RecentlySearched>
      <Title>최근 검색어 : </Title>
      <RecentItemWrapper>
        {recentKeywordValue?.map((keyword: string) => {
          return (
            <RecentItem
              key={keyword}
              onClick={() => {
                recentKeywordHandler(keyword);
              }}
            >
              {keyword}
              <CloseIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setValue(
                    'recentKeyword',
                    recentKeywordValue.filter((item: string) => item !== keyword)
                  );
                  console.log('keyword', keyword);
                  setDeleteSearchedData({
                    keyword: keyword ?? '',
                  });
                }}
                sx={{
                  width: '10px',
                  height: '10px',
                  padding: '3px',
                  color: 'red',
                  border: '1px solid lightgray',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                }}
              />
            </RecentItem>
          );
        })}
      </RecentItemWrapper>
    </RecentlySearched>
  );
};

export default RecentKeywordSection;

const RecentlySearched = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: #303030;
  font-size: 14px;
`;

const Title = styled.p``;

const RecentItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-left: 10px;
`;
const RecentItem = styled.div`
  padding: 5px 5px 5px 10px;
  border-radius: 10px;
  background-color: white;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;
