import styled from '@emotion/styled';
import { VideoType } from '../../utils/searchedDataUtil';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import { formatDate } from '../../../../utils/dayUtil';
import { calcSortSameScore, calcSortScore } from '../../utils/sortUtil';

const VideoItem = ({ item }: { item: VideoType }) => {
  const { title, channel_title } = item;

  const isSmall = window.innerWidth < 550;

  const renderDiffSubscriberCount = () => {
    if (item.channel_subscriber.length < 2) return null;
    const diff =
      Number(item.channel_subscriber[item.channel_subscriber.length - 1]) -
      Number(item.channel_subscriber[item.channel_subscriber.length - 2]);
    const prefix = diff > 0 ? '+ ' : '';
    return (
      <DiffBox diff={diff}>
        ({prefix}
        {diff.toLocaleString()})
      </DiffBox>
    );
  };

  const renderDiffViewCount = () => {
    if (item.view_count.length < 2) return null;
    const diff =
      Number(item.view_count[item.view_count.length - 1]) -
      Number(item.view_count[item.view_count.length - 2]);
    const prefix = diff > 0 ? '+ ' : '';
    return (
      <DiffBox diff={diff}>
        ({prefix}
        {diff.toLocaleString()})
      </DiffBox>
    );
  };

  const renderSearchDateDiff = () => {
    if (item.searchDate.length < 2) return null;

    const diff =
      Number(new Date(item.searchDate[item.searchDate.length - 1])) -
      Number(new Date(item.searchDate[item.searchDate.length - 2]));

    const diffDay = Math.floor(diff / (1000 * 60 * 60 * 24));
    const diffHour = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMin = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const diffSec = Math.floor((diff % (1000 * 60)) / 1000);

    return (
      <SearchedDateWrapper>
        <DiffBox diff={0}>
          검색 사이 시간:
          {diffDay > 0 && `${diffDay}일`} {diffHour > 0 && `${diffHour}시간`}{' '}
          {diffMin > 0 && `${diffMin}분`} {diffSec > 0 && `${diffSec}초`}
        </DiffBox>
        <p>{formatDate(item.searchDate[item.searchDate.length - 2], 'all')}</p>
        <p>{formatDate(item.searchDate[item.searchDate.length - 1], 'all')}</p>
      </SearchedDateWrapper>
    );
  };

  return (
    <Wrapper
      onClick={() => {
        window.open(`https://www.youtube.com/watch?v=${item.id}`, '_blank');
      }}
    >
      <VideoThumbnail src={item.thumbnail} />
      <VideoInfoWrapper>
        <Title>{title}</Title>
        <InfoRowWrapper>
          <div>
            <ChannelWrapper
              onClick={(e) => {
                e.stopPropagation();
                window.open(`https://www.youtube.com/channel/${item.channel_id}`, '_blank');
              }}
            >
              <img src={item.channel_avatar} />
              <p id="channel_name">{channel_title}</p>
            </ChannelWrapper>
            <DateWrapper>
              <span>{item.published_at_text}</span>
              {!isSmall && (
                <>
                  <span>/</span>
                  <span>{formatDate(item.published_at)}</span>
                </>
              )}
            </DateWrapper>
            {renderSearchDateDiff()}
          </div>
          <CountersWrapper isSmall={isSmall}>
            <VideoCountWrapper>
              <div id="label">
                <BookmarkRoundedIcon sx={{ width: isSmall ? '10px' : '15px', color: '#616161' }} />
                구독자
              </div>
              <SubscriberCount
                id="data"
                count={Number(item.channel_subscriber[item.channel_subscriber.length - 1])}
              >
                {Number(
                  item.channel_subscriber[item.channel_subscriber.length - 1]
                ).toLocaleString()}
                명
                <br />
                {renderDiffSubscriberCount()}
              </SubscriberCount>
            </VideoCountWrapper>
            <VideoCountWrapper>
              <div id="label">
                <VisibilityRoundedIcon
                  sx={{ width: isSmall ? '10px' : '15px', color: '#616161' }}
                />
                조회수
              </div>
              <ViewCount id="data" count={Number(item.view_count[item.view_count.length - 1])}>
                {Number(item.view_count[item.view_count.length - 1]).toLocaleString()}회
                {renderDiffViewCount()}
              </ViewCount>
            </VideoCountWrapper>

            <VideoCountWrapper>
              <div id="label">
                <VisibilityRoundedIcon
                  sx={{ width: isSmall ? '10px' : '15px', color: '#616161' }}
                />
                점수
              </div>
              <ViewCount id="data" count={Number(item.view_count[item.view_count.length - 1])}>
                {calcSortScore(item)}/{calcSortSameScore(item)}
              </ViewCount>
            </VideoCountWrapper>
          </CountersWrapper>
        </InfoRowWrapper>
      </VideoInfoWrapper>
    </Wrapper>
  );
};

export default VideoItem;

const Wrapper = styled.div`
  max-width: 800px;

  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border: 1px solid lightgray;
  border-radius: 10px;

  gap: 5px;

  cursor: pointer;

  @media (max-width: 400px) {
    overflow-x: auto;
  }
`;

const VideoThumbnail = styled.div<{ src: string }>`
  background: ${({ src }) => `url(${src}) no-repeat center center`};
  background-size: cover;

  @media (max-width: 550px) {
    width: 100px;
    height: calc(100px / 16 * 9);
  }
  width: 200px;
  height: calc(200px / 16 * 9);

  flex-shrink: 0;
  border-radius: 5px;
`;

const VideoInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 10px;
`;

const InfoRowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

const CountersWrapper = styled.div<{ isSmall: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ isSmall }) => (isSmall ? '3px' : '10px')};
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  /* 특정 단위로 텍스트를 자르기 위한 구문 */
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 텍스트를 자를 때 원하는 단위 ex) 3줄 */
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 550px) {
    font-size: 14px;
    min-width: 100px;
  }
`;

const ChannelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);

    @media (max-width: 550px) {
      width: 15px;
      height: 15px;
    }
  }

  #channel_name {
    font-size: 14px;
    font-weight: 600;

    @media (max-width: 550px) {
      font-size: 12px;
    }
  }

  #channel_subscriber {
    font-size: 14px;
    margin-left: 5px;
    color: gray;
    @media (max-width: 550px) {
      font-size: 12px;
    }
  }
`;

const VideoCountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-shrink: 0;
  min-width: 80px;

  @media (max-width: 550px) {
    /* width: 50px; */
    min-width: 40px;
  }

  #label {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #616161;

    @media (max-width: 550px) {
      font-size: 10px;
      line-height: 1.2;
      gap: 3px;
    }
  }
`;

const SubscriberCount = styled.div<{ count: number }>`
  font-size: 14px;
  font-weight: 600;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;

  @media (max-width: 550px) {
    font-size: 10px;
  }

  color: ${({ count }) => {
    if (count < 5000) {
      return 'green';
    }
    if (count < 10000) {
      return 'green';
    }
    if (count < 50000) {
      return 'orange';
    }
    if (count < 100000) {
      return 'orange';
    }
    return 'black';
  }};

  font-weight: ${({ count }) => {
    if (count < 5000) {
      return '900';
    }
    if (count < 10000) {
      return '800';
    }
    if (count < 50000) {
      return '700';
    }
    if (count < 100000) {
      return '600';
    }
    return '500';
  }};
`;

const ViewCount = styled(SubscriberCount)`
  color: ${({ count }) => {
    if (count < 5000) {
      return 'black';
    }
    if (count < 10000) {
      return 'black';
    }
    if (count < 20000) {
      return 'orange';
    }
    if (count < 100000) {
      return 'green';
    }
    return 'green';
  }};

  font-weight: ${({ count }) => {
    if (count < 5000) {
      return '400';
    }
    if (count < 10000) {
      return '500';
    }
    if (count < 50000) {
      return '800';
    }
    if (count < 100000) {
      return '900';
    }
    return '900';
  }};
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 13px;
  color: gray;
  font-weight: 600;
  gap: 5px;

  margin-top: 10px;
`;

const DiffBox = styled.div<{
  diff: number;
}>`
  font-size: 13px;
  color: ${({ diff }) => (diff > 0 ? '#ff3d3d' : '#4c4c4c')};
  font-weight: 800;
  gap: 5px;
`;

const SearchedDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  font-size: 13px;
  color: gray;
  font-weight: 500;
  gap: 6px;
  margin-top: 15px;
`;
