import styled from '@emotion/styled';

import hologram from '../../../../assets/Image/hologram.jpg';
import SearchIcon from '@mui/icons-material/Search';
import useFormContextHook from '../../../../hooks/useFormContextHook';
import { setSearchedData } from '../../utils/searchedDataUtil';
import RecentKeywordSection from './RecentKeywordSection';
import { useGetVideoByKeyword } from '../../../../api/fetchGetVideos';
import { KeyboardEvent } from 'react';

const SearchSection = () => {
  const { setValue, watch } = useFormContextHook();

  const recentKeywordValue = watch('recentKeyword') ?? [];
  const { refetch } = useGetVideoByKeyword({
    keyword: watch('keyword') ?? '',
    limit: watch('limit') ?? 20,
  });

  const submitHandler = async () => {
    const keyword = watch('keyword');

    if (!keyword || keyword.length === 0) return alert('검색어를 입력해주세요');
    const fetchResult = await refetch();

    const result = fetchResult.data;

    if (!result || !result?.success) {
      alert('검색에 실패했습니다');
      return;
    }

    //이미 존재하는 키워드인지 확인
    if (recentKeywordValue.includes(watch('keyword'))) {
      //이미 존재한다면 맨 뒤로 이동
      const newRecentKeywordValue = recentKeywordValue.filter(
        (keyword: string) => keyword !== watch('keyword')
      );
      setValue('recentKeyword', [...newRecentKeywordValue, watch('keyword')]);
    } else {
      //존재하지 않는다면 추가
      if (recentKeywordValue.length < 20) {
        // 배열 길이가 특정 갯수 이하이면 그대로 추가
        setValue('recentKeyword', [...recentKeywordValue, watch('keyword')]);
      } else {
        // 배열 길이가 특정 갯수를 초과하면 가장 오래된 키워드부터 제거 후 추가
        setValue('recentKeyword', [...recentKeywordValue.slice(1), watch('keyword')]);
      }
    }

    setValue('result', {
      keyword: result.keyword,
      value: result.data,
    });
    setSearchedData({
      keyword: result.keyword ?? '',
      value: result.data ?? [],
    });
  };

  const handleOnKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      submitHandler(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  return (
    <Wrapper>
      <SearchWrapper>
        <InputWrapper>
          <Input
            type="text"
            placeholder="Search.."
            name="search"
            value={watch('keyword') ?? ''}
            onChange={(e) => {
              setValue('keyword', e?.target?.value);
            }}
            onKeyUp={handleOnKeyPress}
          />
          <LimitWrapper>
            <p>limit:</p>
            <select
              name="limit"
              onChange={(e) => {
                setValue('limit', e?.target?.value ?? 10);
              }}
            >
              <option value="20" selected={watch('limit') === '20'}>
                20
              </option>
              <option value="50" selected={watch('limit') === '50'}>
                50
              </option>
              <option value="100" selected={watch('limit') === '100'}>
                100
              </option>
              <option value="200" selected={watch('limit') === '200'}>
                200 (약 35s)
              </option>
              <option value="300" selected={watch('limit') === '300'}>
                300
              </option>
            </select>
          </LimitWrapper>
          <SubmitButton onClick={submitHandler}>
            <SearchIcon sx={{ width: '24px', height: '24px' }} />
          </SubmitButton>
        </InputWrapper>
      </SearchWrapper>
      <RecentKeywordSection />
    </Wrapper>
  );
};

export default SearchSection;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 30px;
  background-color: #60bbe3;
  background-image: url(${hologram});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  gap: 15px;
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 5px 5px 5px 20px;
  gap: 10px;

  background-color: white;
  border-radius: 30px;
`;

const Input = styled.input`
  border: none;
  width: 40vw;
  min-width: 200px;

  font-size: 15px;
`;

const SubmitButton = styled.div`
  border: none;
  border-radius: 30px;

  background-color: #4e49fa;

  width: auto;
  height: 100%;
  padding: 10px;
  color: white;
  font-size: 13px;
  font-weight: 700;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  cursor: pointer;
`;

const LimitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 5px;

  font-size: 13px;

  select {
    border: none;
    font-size: 15px;
  }
`;
