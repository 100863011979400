import { ReactElement } from 'react';
import useFormContextHook from '../../../hooks/useFormContextHook';

import { View } from '../../../components/common/Layout';
import SearchSection from './SearchSection/SearchSection';
import ContentsSection from './ContentsSection/ContentsSection';
import styled from '@emotion/styled';

// 정렬기준
export type SortContents = 'default' | 'diffCount';

function MainContents(): ReactElement {
  const { watch, setValue } = useFormContextHook();
  const sort: SortContents = watch('sort');

  return (
    <View>
      <SearchSection />
      <ButtonWrapper>
        정렬:
        <Button
          checked={sort === 'default'}
          onClick={() => {
            setValue('sort', 'default');
          }}
        >
          구독자/조회수
        </Button>
        <Button
          checked={sort === 'diffCount'}
          onClick={() => {
            setValue('sort', 'diffCount');
          }}
        >
          diff순
        </Button>
      </ButtonWrapper>
      <ContentsSection />
    </View>
  );
}

export default MainContents;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;

  margin: 20px 0 10px 0;
`;

const Button = styled.div<{
  checked: boolean;
}>`
  cursor: pointer;
  border-radius: 15px;
  padding: 5px 10px;
  border: 1px solid #009ee2;
  background: ${({ checked }) => (checked ? '#009ee2' : 'transparent')};
  color: ${({ checked }) => (checked ? 'white' : 'black')};
  font-size: 14px;
  font-weight: ${({ checked }) => (checked ? '600' : '400')};
`;
