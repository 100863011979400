import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { useGetVideoByKeyword } from '../../../../api/fetchGetVideos';
import useFormContextHook from '../../../../hooks/useFormContextHook';
import { getSearchedData, VideoType } from '../../utils/searchedDataUtil';

import EmptyResult from '../EmptyResult';
import IsFetchingResult from '../IsFetchingResult';
import VideoList from '../ResultVideoList/VideoList';

export type ContentsResultType = {
  keyword: string;
  value?: VideoType[];
};

const ContentsSection = () => {
  const { watch, setValue } = useFormContextHook();
  const currentSearchResult: ContentsResultType = watch('result');
  const result = getSearchedData({ keyword: currentSearchResult.keyword ?? '' });

  const ref = React.useRef<HTMLDivElement>(null);
  const { isFetching, refetch } = useGetVideoByKeyword({
    keyword: watch('keyword') ?? '',
    limit: watch('limit') ?? 20,
  });

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setValue('scrolledToContents', false);
          } else {
            setValue('scrolledToContents', true);
          }
        });
      });
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  const checkActiveRateHandler = () => {
    refetch();
  };

  if (isFetching) return <IsFetchingResult />;

  if (!result.length) return <EmptyResult />;

  return (
    <>
      <TextInfo ref={ref}>
        <p style={{ marginRight: '5px', fontWeight: '700', fontSize: '18px' }}>
          {currentSearchResult?.keyword}
        </p>
        검색결과 :{' '}
        <p style={{ margin: '0 5px', fontWeight: '700', fontSize: '18px' }}>
          {result?.length ?? 0}
        </p>
        개 <Button onClick={checkActiveRateHandler}>노출확률분석</Button>
      </TextInfo>
      <br />
      <VideoList value={result} keyword={currentSearchResult.keyword} />
    </>
  );
};

export default ContentsSection;

const TextInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const Button = styled.div`
  margin-left: 15px;
  padding: 6px 10px;
  border-radius: 5px;
  background-color: #4385ff;
  color: white;
  font-weight: 600;
  cursor: pointer;
`;
