import axios from 'axios';

export const API_BASE_URL =
  process.env.REACT_APP_ENV === 'dev' ? 'https://dave-ai.p-e.kr/api' : 'https://dave-ai.p-e.kr/api';

export const initFetchInstance = (baseURL: string) =>
  axios.create({
    baseURL,
    timeout: 360000,
    headers: {
      Accept: 'application/json',
    },
  });

export const fetchInstance = () => initFetchInstance(API_BASE_URL);
