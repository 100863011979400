import styled from '@emotion/styled';
import React from 'react';

const EmptyResult = () => {
  return <Wrapper>검색결과가 없습니다.</Wrapper>;
};

export default EmptyResult;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
