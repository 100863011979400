import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

interface SpinnerProps {
  size?: number;
  color?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ size = 50, color = 'gray' }) => {
  return (
    <Wrapper size={size} color={color}>
      <Inner color={color} />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ size: number; color: string }>`
  display: inline-block;
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

const spinAnimation = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Inner = styled.div<{ color: string }>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: ${({ color }) => `4px solid ${color}`};
  border-top-color: transparent;
  animation: ${spinAnimation} 1s linear infinite;
`;

export default Spinner;
