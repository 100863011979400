import { ReactElement } from 'react';
import useGetNativeEventBridge from './hooks/useGetNativeEventBridge';
import './utils/firebase';
import 'react-spring-bottom-sheet/dist/style.css';
import './styles/bottomsheet.css';
import { FormProvider, useForm } from 'react-hook-form';
import { Stack } from './stackflow';
import { storage } from './hooks/useStorage';

function App(): ReactElement {
  useGetNativeEventBridge();

  // 앱 시작일 저장 - 앱 시작일 기준으로 몇주차인지 계산하기 위함
  const keyword = storage('keyword').get();
  const result = storage('result').get();
  const recentKeyword = storage('recentKeyword').get();
  const limit = storage('limit').get();
  const sort = storage('sort').get();

  const formMathod = useForm({
    defaultValues: {
      keyword,
      result,
      recentKeyword,
      limit,
      sort,
    },
  });

  return (
    <FormProvider {...formMathod}>
      <Stack />
    </FormProvider>
  );
}

export default App;
